import Sidebar from '@/components/Sidebar'

export default {
  name: 'dashboard',

  components: {
    Sidebar
  },

  data() {
    return {
      modules: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'chart-bar'
        },
        {
          name: 'Proveedores',
          path: '/providers',
          icon: 'people-carry'
        },
        {
          name: 'Compañías',
          path: '/companies',
          icon: 'hotel',
          items: [
            {
              name: 'Polo Hospitality',
              path: '/company/polo_hospitality'
            }
          ]
        }
      ]
    }
  },

  methods: {

  }
}
